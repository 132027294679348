<template>
  <div class="submit-seq">
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScoJ-2BOeITCSnHrFxjox6Or2msxRCAuz-sPOotfmDTYvzf2A/viewform?embedded=true" max-width="640" width="100%" height="1700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
  </div>
</template>

<style scoped>
.submit-seq{
  text-align: center;
}
</style>